import { Layout } from "../../../home/Layout"

export const CustomCategoriesHome = () => {

  return (
    <Layout>
      <div>
        Custom Categories Home
      </div>
    </Layout>
  )
}