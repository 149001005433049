import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppContextWrapper } from '../../../shared/contexts/AppContextWrapper';

interface IProps {
  categoryName: string;
  categoryId?: string;
  sharedBy: string;
}

export const SharedCategoryCards = ({ categoryName, categoryId, sharedBy }: IProps) => {
  const navigate = useNavigate();
  const { appStateSetter } = useAppContextWrapper();

  const handleClick = () => {
    if (categoryId) {
      appStateSetter(prev => ({
        ...prev, flashCardProfLevel: 'sharedCategory',
        flashCardsMyCustomCategory: categoryId
      }));
      navigate('/flashCards')
    }
  }

  return (
    
      <Card className='col-9 h-100 p-0'>
        <Card.Body className='my_categories_font h-100 d-flex flex-column p-0' onClick={handleClick}
         >
            <Card.Text className='h-100 d-flex justify-content-center align-items-end'>
              {categoryName}
            </Card.Text>
            <Card.Text className='h-100 d-flex align-items-end justify-content-center'>
              <p className='fs-6'>shared by: <span className='d-block'>{sharedBy}</span></p>
            </Card.Text>
          
        </Card.Body >
      </Card >
    
  )
}