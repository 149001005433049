import styled, { css } from "styled-components";

export const FormStyle = styled.div`
    .form-header{
        color:#7B7B9B ;
    }
    .form-title{
        color:#3E3958;
        font-size:1.9vw;
    }
    .link-color{
        color:#3E3958;
        font-size:1.4vw;
    }
    .form-head{
        color:#3E3958;
        font-size:1.4vw;
    }
    .letter-spacing{
        letter-spacing: 2.5px;
    }
    .btn:hover{
        background-color:#4B4D77;
        color:white !important;
        border-color:#4B4D77 !important; 
    }
    
`;
