import { apiCall } from "./api";
import { ICategoriesByProfData } from "./shared/contexts/AppContextWrapper";

interface PlayAudioProps {
	audioRef: React.MutableRefObject<HTMLAudioElement | null>;
	LanguageCode: string;
	text: string;
	voc_id?:string;
}

interface IVokab {
	playAudio: (props: PlayAudioProps) => void;
}

export default class Vokab {
	// export const getSpeakUrl = async (languageCode: String="en",text: string="How can i help you?", ) => apiCall<ICategoriesByProfData>({ method: 'GET', url: `common/tts`,params:{

	static getSpeakUrl(languageCode: string, text: string,voc_id?:string): string {
		return `common/tts?languageCode=${languageCode}&text=${text}&voc_id=${voc_id}`;
	}

	static async playAudio(props: PlayAudioProps, signal: AbortSignal) {
		// Implementation of the playAudio function goes here
		// You can access the props.LanguageCode and props.text inside this function
		if (props.audioRef.current) {
			const result = await apiCall<ICategoriesByProfData>(
				{
					method: "GET",
					url: this.getSpeakUrl(props.LanguageCode, props.text,props.voc_id),
				},
				signal
			);
			const { base64 } = result?.[0]?.result?.data as any;
			if (base64) {
				// const audio = new Audio();
				props.audioRef.current.src = `data:audio/mp3;base64,${base64}`;
				try {
					// props.audioRef.current = audio;
					props.audioRef.current.play();
				} catch (error) {}
			}
		}
	}
}
