import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoriesTableFilterParams } from '@vokab/shared/src/types';
import { number, Optionals } from 'yup';


	// userId: "",
	
	// languageIds: [],

type Preferences={
	start: number,
	limit?: number,
	categoryId?: string;
	isCompetition?: boolean;
	isCustomCategory?: boolean;
	isSharedCategory?: boolean;
	grammarCategoryName?:string,
	userId?:string,
	languageIds?:string[]
}



export interface AppState {
	// Define your state properties here
	// For example:
	search: string;
	counter: number;
	isFetching: boolean;
	isGridDataFetching: boolean;
	preferences:CategoriesTableFilterParams
}

const initialState: AppState = {
	counter: 0,
	search:"",
	isFetching: false,
	isGridDataFetching: false,
	preferences:{
		start: 0,
		limit: 20,
		categoryId: "",
		isCompetition: false,
		isCustomCategory: false,
		isSharedCategory: false,
		grammarCategoryName:"",
		userId:"",
		languageIds:[]
	}
	
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		incrementCounter(state) {
			state.counter += 1;
		},
		decrementCounter(state) {
			state.counter -= 1;
		},
		resetCounter(state) {
			state.counter = 0;
		},
		setCounter(state, action: PayloadAction<number>) {
			state.counter = action.payload;
		},
		setSearch(state, action: PayloadAction<string>) {
			state.search = action.payload;
		},
		setFetching(state, action: PayloadAction<boolean>) {
			state.isFetching = action.payload;
		},
		setGridDataFetching(state, action: PayloadAction<boolean>) {
			state.isGridDataFetching = action.payload;
		},
		setPreferences(state, action: PayloadAction<Partial<Preferences>>) {
			state.preferences = {...state,
				start:action.payload.start || state.preferences.start,
				limit:action.payload.limit || state.preferences.limit,
				...action.payload
			};
		}

	},
});

export const {
	incrementCounter,
	decrementCounter,
	resetCounter,
	setCounter,
	setFetching,
	setPreferences,
	setGridDataFetching
} = appSlice.actions;


export const {reducer:appReducer}= appSlice;
