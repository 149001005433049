import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILanguage } from "@vokab/shared/src/types";
const preferences={
	sourceLanguage: {
		languageId: "b23ef9d1-961d-4b98-8c21-61b74c1f2674",
		languageFamilyId: "19638094-0e07-475f-8362-4f1320f76aa6",
		languageCode: "ta",
		displayFlag: true,
		languageName: "Tamil",
		languageFamilyName: "Dravidian",
	} as Partial<ILanguage>,
	targetLanguage: {
		languageId: "b6f38e90-54ae-41c6-8a2f-1d86e5cd7b34",
		languageFamilyId: "b8bb5ca1-481c-487e-9741-7756a6a7ad3e",
		languageCode: "en",
		displayFlag: true,
		languageName: "English",
		languageFamilyName: "Others",
	} as Partial<ILanguage>
}

const languageSlice = createSlice({
	name: "languages",
	initialState: {
		data: [] as ILanguage[],
		sourceLanguage:preferences.targetLanguage,
		targetLanguage: preferences.sourceLanguage,
	},
	reducers: {
		setLanguages: (state, action: PayloadAction<ILanguage[]>) => {
			state.data = action.payload;
		},
		setSourceLanguage: (state, action: PayloadAction<Partial<ILanguage>>) => {
			state.sourceLanguage = action.payload;
		},
		setTargetLanguage: (state, action: PayloadAction<Partial<ILanguage>>) => {
			state.targetLanguage = action.payload;
		},

		
	},
});

export const { actions:languageActions,reducer: languageReducer} = languageSlice;



