/* eslint-disable react-hooks/exhaustive-deps */
import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import {  FormikProps } from 'formik';
import { userRegistration } from "@vokab/shared/src/schemas";
import { Any, IUpdateProfile, LoginPayload } from "@vokab/shared/src/types";
import Form from 'react-bootstrap/Form';
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import { Layout } from "../home/Layout";
import { FlashCaredStyle } from "../flashCards/flashcared";
import { Col, Container, Row } from "react-bootstrap";
import { useUserHook } from "../../shared/hooks/useUserHook";
import { CustomStyle } from "../ChangePassword/style";
import Notify from "../../shared/components/Notify";
import authApi from "../../store/services/authAPI";
import { useEffect } from "react";
import { useNotificationContextWrapper } from "../../shared/contexts/NotificationContextWrapper";

export default function Profile() {
    const { user } = useUserContextWrapper();
    const { getMe } = useUserHook();
	const [updateProfile,{data,error}]=	authApi.useUserProfileUpdateMutation()
	const {notifySuccess,notifyError}=useNotificationContextWrapper()
	useEffect(() => error && notifyError(error), [error]);
	useEffect(() => data && notifySuccess(data?.result?.data || "Profile updated successfully"),[data]);
    const initialValues = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email
    };

    const submit = async (values: IUpdateProfile) => {
        await updateProfile({
            firstName: values.firstName,
            lastName: values.lastName,
            userId: user?.userId ?? ''
        })
       await getMe()
    };

    return (
        <Layout>
            <div className="container-fluid col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12">
                <FlashCaredStyle>
                    <Row className='m-0 mt-3 justify-content-between align-items-center col-12'>
                        <Col className="p-0 col-12">
                            <Container className="d-flex flex-wrap justify-content-center align-items-center MyDropdownContainer my_vokab_table">
                                <div style={{ color: '#4B4D77', fontSize: '1.6vw ', fontWeight: 'bold' }}>
                                    my profile
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </FlashCaredStyle>
            </div>
            <hr className="col-11 mx-auto" />
            <div className="mx-auto d-flex justify-content-center align-items-start lh-lg col-12" style={{ height: 'calc(100vh - 14vw)' }}>
                <Notify/>
                <FormikWithWrapper
                    enableReinitialize
                    validateOnMount
                    initialValues={initialValues}
                    validationSchema={userRegistration.omit(['confirmPassword','password' ]) as Any}
                    onSubmit={submit}
                >
                    {
                        (props: FormikProps<LoginPayload>) => (
                            <Form className="pt-3 col-6 " onSubmit={props.handleSubmit} noValidate >
                            <Form.Group className="mb-3" controlId="firstName" >
                                <Form.Label style={{ color: '#4B4D77', fontWeight: 'bold' }}>first name</Form.Label>
                                <TextInput name='firstName' type="text" placeholder="first name" className=" border-3" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label style={{ color: '#4B4D77', fontWeight: 'bold' }}>last name</Form.Label>
                                <TextInput name='lastName' type="text" placeholder="last name" className=" border-3" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label style={{ color: '#4B4D77', fontWeight: 'bold' }}>email</Form.Label>
                                <TextInput name='email' type="email" placeholder="email" className=" border-3" disabled/>
                            </Form.Group>

                            <CustomStyle>
                                <div className=" pt-3 col-12 d-flex justify-content-center align-items-end" style={{ height: '100%'}}>
                                    <LoginButton type="submit" className="btn btn-primary form-button mx-auto m-3 rounded-5">save</LoginButton>
                                </div>
                            </CustomStyle>
                        </Form>
                        )
                    }
                </FormikWithWrapper>
            </div>
        </Layout>
    );
}