/* eslint-disable react-hooks/exhaustive-deps */
import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { FormikProps } from 'formik';
import { resetPasswordSchema } from "@vokab/shared/src/schemas";
import { Any, ResetPasswordPayload } from "@vokab/shared/src/types";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import { Layout } from "../home/Layout";
import { FlashCaredStyle } from "../flashCards/flashcared";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useMemo } from "react";
import { CustomStyle } from "./style";
import Form from 'react-bootstrap/Form';
import Notify from "../../shared/components/Notify";
import authApi from "../../store/services/authAPI";
import { useNotificationContextWrapper } from "../../shared/contexts/NotificationContextWrapper";

export default function ChangePassword() {
		const [changePassword,{data,error}]=authApi.useResetPasswordMutation()
		const {notifyError,notifySuccess}=useNotificationContextWrapper();

		useEffect(() => error && notifyError(error), [error]);
		useEffect(() => {data && notifySuccess('password changed successfully')}, [data]);
    const { user } = useUserContextWrapper();
    const initialValues = {
        confirmPassword: '',
        password: ''
    };

    const userId = useMemo(() => user?.userId, [user]);

    const submit = async (values: ResetPasswordPayload) => {
        if (userId) {
            await changePassword({
                userId: userId ?? '',
                password: values.password,
                confirmPassword: values.confirmPassword
            });
        }
    };

    return (
        <Layout>
            <div className="container-fluid col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12">
                <FlashCaredStyle>
                    <Row className='m-0 mt-3 justify-content-between align-items-center col-12'>
                        <Col className="p-0 col-12">
                            <Container className="d-flex flex-wrap justify-content-center align-items-center MyDropdownContainer my_vokab_table">
                                <div className="d-flex align-items-center" style={{ color: '#4B4D77', fontSize: '1.6vw ', fontWeight: 'bold' }} >
                                    change password
                                </div>
                            </Container>
                        </Col>
                    </Row>

                </FlashCaredStyle>
            </div>
            <hr className="col-11 mx-auto" />
            <div className="mx-auto d-flex justify-content-center align-items-start lh-lg col-12" style={{ height: 'calc(100vh - 250px)' }}>
                <Notify/>
                <FormikWithWrapper
                    enableReinitialize
                    validateOnMount
                    initialValues={initialValues}
                    validationSchema={resetPasswordSchema as Any}
                    onSubmit={submit}
                >
                    {
                        (props: FormikProps<ResetPasswordPayload>) => (
                            <Form className="pt-3 col-6 " onSubmit={props.handleSubmit} noValidate >
                                <Form.Group className="mb-3" controlId="Password" >
                                    <Form.Label style={{ color: '#4B4D77', fontWeight: 'bold' }}>password</Form.Label>
                                    <TextInput name='password' type="password" placeholder="new password" className=" border-3" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="confirmPassword">
                                    <Form.Label style={{ color: '#4B4D77', fontWeight: 'bold' }}>confirm password</Form.Label>
                                    <TextInput name='confirmPassword' type="password" placeholder="re-enter new password" className=" border-3" />
                                </Form.Group>

                                <CustomStyle>
                                    <div className=" pt-3 col-12 d-flex justify-content-center align-items-end " style={{ height: '100%' }}>
                                        <LoginButton type="submit" className="btn btn-primary form-button mx-auto m-3 rounded-5">save</LoginButton>
                                    </div>
                                </CustomStyle>
                            </Form>
                        )
                    }
                </FormikWithWrapper>
            </div>


        </Layout >


    );
}