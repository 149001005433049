import { useField } from "formik";
import { Form, FormControlProps } from "react-bootstrap";
import { useGetInputRequiredValue } from "../../hooks/useGetInputRequiredValue";

interface Props extends FormControlProps {
	name: string;
	isRequired?: boolean;
	placeholder?: string;

}

export default function TextInput({ name, isRequired , ...props}: Props) {
	const [field, meta] = useField(name);
	const _isRequired = useGetInputRequiredValue(isRequired, name);
	const { error, touched } = meta;

	return (
		<>
			<Form.Control
				className={`mb-1 ${props.className ? props.className : ''}`}
				{...props} required={_isRequired} {...field}
				isInvalid={touched && !!error}/>
			{touched && !!error && (
				<Form.Control.Feedback className="mb-1" type="invalid">
				{error}
				</Form.Control.Feedback>
			)}
		</>
	)
}