import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { proLevelReducer } from './slices/levelSlice'
import { lessonReducer } from './slices/lessonSlice'
import { languageReducer } from './slices/languageSlice'
// import notificationSlice from './slices/notificationSlice'
import api from './services/personalCategoryAPI'
import sharedCategoryAPI from './services/sharedCategoryAPI'
import userAPI from './services/userAPI'
import authAPI from './services/authAPI'
import categoriesAPI from './services/categoriesAPI'
import {appReducer} from "./slices/appSlice"


export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
  
		profLevels: proLevelReducer,
		lessons:lessonReducer,
		languages:languageReducer,
		appData:appReducer,
		[api.reducerPath]: api.reducer,
		[sharedCategoryAPI.reducerPath]:sharedCategoryAPI.reducer,
		[userAPI.reducerPath]:userAPI.reducer,
		[authAPI.reducerPath]:authAPI.reducer,
		[categoriesAPI.reducerPath]:categoriesAPI.reducer,

		// notifications:notificationSlice.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
getDefaultMiddleware().concat(api.middleware, sharedCategoryAPI.middleware,userAPI.middleware,authAPI.middleware,categoriesAPI.middleware),
devTools: true
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch