import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFlashCardsContextWrapper } from '../../../shared/contexts/FlashCardContext';
import { ICategoryByProf } from '@vokab/shared/src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


interface IProps {
  sourceLanguage: string;
  categoryId: string;
  targetLanguage: string;
  categoryName: ICategoryByProf;
  customCategoryId: string;
	sourceVocabId: string;
	targetVocabId: string;
}

export const Cards = ({ sourceLanguage, categoryId, targetLanguage, categoryName, customCategoryId }: IProps) => {
  const navigate = useNavigate();

  const { FlashCardsStateSetter } = useFlashCardsContextWrapper()
  const handleClick = () => {
    FlashCardsStateSetter(prev => ({
      ...prev, categories: categoryId,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      categoryName: categoryName.word,
      customCategoryId: customCategoryId
    }));
    navigate('/flashCards/categories')
  }

  const getCategoryName = (value: string, ct: ICategoryByProf, word: string) => {
    if (value === 'English')
      return ct.word
    else
      return word ?? 'No Data'
  }

  return (

    <Card onClick={handleClick} className='d-flex justify-content-between'>

      <Card.Body className='my_categories_font' style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <Card.Text className={sourceLanguage.toLowerCase()==="tamil"?"ta-text fs-5 text-center":"text-center"}>
          {getCategoryName(sourceLanguage, categoryName, categoryName.language1)}
        </Card.Text>
        <FontAwesomeIcon icon={faCircle} size="xs" />
        <Card.Text className={targetLanguage.toLowerCase()==="tamil"?"ta-text fs-5 text-center":"text-center"} style={{
          fontWeight: '500',

        }}>
          {getCategoryName(targetLanguage, categoryName, categoryName.language2)}
        </Card.Text>
      </Card.Body >

    </Card >
  )
}