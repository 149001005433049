/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Navbar, Nav } from "react-bootstrap";
import { AppColorBackground } from "../../styled-components/common";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import SidebarPanel from "./SidebarPanel";
import { useEffect, useState } from "react";
import NavbarActions from "./NavbarActions";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import sharedCategoryAPI from "../../store/services/sharedCategoryAPI";
import { useAppDispatch, useAppSelector } from "../../store";
const CustomNavBar = styled(Navbar)`
  div.navbar-nav {
    a.nav-link {
      color: var(--text-white) !important;
      font-size: 1.4vw;
      font-weight: normal;
     
      
      }
		a.nav-link:hover {
        font-weight: bold;
      }
    a.nav-link > button, a.nav-link > .dropdown > button {
      color: var(--text-white);
      border-color: var(--text-white);
      font-size: 1.4vw;
      width:85%;

      
     
    }
   
 
    a.nav-link > button:hover, a.nav-link > .dropdown > button:hover {
      background-color: var(--text-white);
      color: var(--app-color1);
      font-weight: bold;
      
    }
 
    div > svg {
      font-size: 1.5vw;
    }
    .container-fluid{
      height:100px;
    }
  .large-btn .nav-link{
    width:80%;
    display:flex;
    justify-content:end;
    align-items:center;
    padding:0;
  }
`;

export default function Header() {
	const [showPanel, setShowPanel] = useState<boolean>(false);
	const search = useAppSelector((state) => state.appData.search);
	const dispatch = useAppDispatch();
	const { user } = useUserContextWrapper();
	const [fetch, data] =
		sharedCategoryAPI.useGetAllUnDeliveredByReceiverIdMutation();
	useEffect(() => {
		if (user?.userId) fetch({ to_user_id: user?.userId }).then();
	}, [user]);

	const p = window.location.pathname;
	const getStyle = (path: string) => {
		if (path === p)
			return {
				fontWeight: "bold",
			};
		return {};
	};

	const nav = useNavigate();


	const handleSearch = () => {
		p !== "/vocabulary" && nav("/vocabulary");
	
	};


	return (
		<AppColorBackground >
			<SidebarPanel show={showPanel} handleClose={() => setShowPanel(false)} />
			<CustomNavBar collapseOnSelect expand="lg" className="p-0">
				<Container
					fluid
					className="d-flex justify-content-between align-items-center nav-header p-0 flex-wrap"
				>
					<div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4">
		
						<Navbar.Brand
							href="/"
							className=" d-flex justify-content-center align-items-center "
						>
							<img
								src="/logo.png"
								style={{ width: "5.5rem" }}
								className="d-inline-block align-top p-0"
								alt="App logo"
							/>
						</Navbar.Brand>
					</div>
					<Navbar.Toggle className="col-8  d-flex  justify-content-end d-lg-none" />

					<Navbar.Collapse id="basic-navbar-nav d-flex justify-content-end w-100">
						<Nav className=" d-flex  col-11 ">
							<div className="d-flex justify-content-between align-items-center col-11 nav-row">
								<div className="d-flex justify-content-between col-10   nav-row">
									<Nav.Link style={getStyle("/")} as={Link} to={"/"}>
										home
									</Nav.Link>
									<Nav.Link
										style={getStyle("/vocabulary")}
										as={Link}
										to={"/vocabulary"}
									>
										vocabulary
									</Nav.Link>
									<Nav.Link
										as={Link}
										style={getStyle("/flashcards")}
										to={"/flashcards"}
									>
										flashcards
									</Nav.Link>
									<Nav.Link
										as={Link}
										style={getStyle("/events")}
										to={"/events"}
									>
										events
									</Nav.Link>
									<Nav.Link as={Link} style={getStyle("/about")} to={"/about"}>
										about us
									</Nav.Link>
									<Nav.Link>
											<form className="bd-search position-relative me-auto">
												<span
													className="algolia-autocomplete"
													style={{
														position: "relative",
														display: "inline-block",
														direction: "ltr",
													}}
												>
													<input
														type="search"
														className="form-control ds-input"
														id="search-input"
														placeholder="search vocabulary..."
														aria-label="Search docs for..."
														autoComplete="off"
														data-bd-docs-version="5.0"
														spellCheck="false"
														role="combobox"
														aria-autocomplete="list"
														aria-controls="algolia-autocomplete-listbox-0"
														aria-expanded="false"
														aria-owns="algolia-autocomplete-listbox-0"
														dir="auto"
														style={{
															position: "relative",
															verticalAlign: "top",
														}}
														value={search}
														onChange={(e) => {
															dispatch({
																type: "app/setSearch",
																payload: e.target.value,
															});
										
														}}
														onKeyDownCapture={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																handleSearch();
															}
														}}
													/>
													<pre
														aria-hidden="true"
														style={{
															position: "absolute",
															visibility: "hidden",
															whiteSpace: "pre",
															fontFamily:
																"system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
															fontSize: "16px",
															fontStyle: "normal",
															fontVariant: "normal",
															fontWeight: 400,
															wordSpacing: "0px",
															letterSpacing: "normal",
															textIndent: "0px",
															textRendering: "auto",
															textTransform: "none",
														}}
													></pre>
													<span
														className="ds-dropdown-menu"
														role="listbox"
														id="algolia-autocomplete-listbox-0"
														style={{
															position: "absolute",
															top: "100%",
															zIndex: 100,
															left: "0px",
															right: "auto",
															display: "none",
														}}
													>
														<div className="ds-dataset-1"></div>
													</span>
												</span>
											</form>
										</Nav.Link>
								</div>
								<div className="col-lg-3 ms-3">
		
									<NavbarActions />
	
								</div>
							</div>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</CustomNavBar>
		</AppColorBackground>
	);
}
