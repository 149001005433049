import {
	ICategory, ILanguage, CategoriesTableFilterParams, IProfLevel,
	IGrammarCategory, ICategoriesTableData, CategoriesByProfParams, CategoriesById, MyCategoriesByUserParams, IMyCategoryPayload, IMyCategoryDetailsPayload, IAllUsers, IShareMyCategoryPayload, IRemoveWordPayload,
} from "@vokab/shared/src/types";
import { apiCall, apiCallWithLoading } from "./";
import { ICategoriesByIdData, ICategoriesByProfData, ICustomCategoriesByUserIdData } from "../shared/contexts/AppContextWrapper";
import axios, { CancelTokenSource } from "axios";
let source: CancelTokenSource;
export const allCategories = () => apiCall<ICategory[]>({ method: 'GET', url: 'common/categories' });
export const allLanguages = () => apiCall<ILanguage[]>({ method: 'GET', url: `common/languages` });
export const categoriesTableRequest = (filterParams: CategoriesTableFilterParams) => {
	const {signal,...data}=filterParams
	
	return apiCall<ICategoriesTableData>({ method: 'POST', url: 'common/categories/grid', data: data },signal)
};
export const profLevelsRequest = () => apiCall<IProfLevel[]>({ method: 'GET', url: 'common/prof-levels' });
export const grammarCategoryRequest = () => apiCall<IGrammarCategory[]>({ method: 'GET', url: 'common/pos' });
export const categoriesByProf = (params: CategoriesByProfParams) => (
	apiCall<ICategoriesByProfData>({ method: 'POST', url: 'common/categoriesByProf', data: params})
);
export const categoriesByIdRequest = (filterParams: CategoriesById) => (
	apiCall<ICategoriesByIdData>({ method: 'POST', url: 'common/categoryDetailsById', data: filterParams })
);
export const myCategoriesByUserIdRequest = (filterParams: MyCategoriesByUserParams) => (
	apiCall<ICustomCategoriesByUserIdData>({ method: 'POST', url: 'common/myCategories', data: filterParams })
);

export const mySharedCategoriesByUserIdRequest = (filterParams: MyCategoriesByUserParams) => (
	apiCall<ICustomCategoriesByUserIdData>({ method: 'POST', url: 'common/sharedCategories', data: filterParams })
);

export const createMyCategoryPayload = (payload: IMyCategoryPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'common/createMyCategory',
	data: payload
});


export const addMyCategoryDetailsRequest = (payload: IMyCategoryDetailsPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'common/addCategoryDetails',
	data: payload
});

export const allUsers = () => apiCall<IAllUsers[]>({ method: 'GET', url: 'common/allUsers' });

export const shareMyCategoryRequest = (payload: IShareMyCategoryPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'common/shareMyCategory',
	data: payload
});

export const removeCategoryDetailsRequest = (payload:IRemoveWordPayload) => apiCallWithLoading<string>({
	method: 'DELETE',
	url: `common/remove`,
	data:payload
});