import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import AboutUs from "../pages/about_us";
import ChangePassword from "../pages/ChangePassword/password";
import { EventsHome } from "../pages/events";
import { SharedCategories } from "../pages/flashCards/sharedCategory.tsx";
import ForgotPassword from "../pages/forgotPassword";
import Home from "../pages/home";
import Profile from "../pages/Myprofile/profile";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import { VocabularyHome } from "../pages/vocabulary";
import { useUserHook } from "../shared/hooks/useUserHook";
import AuthRoutes from "./authRoutes";
import FlashcardsRouter from "./flashCardsRoutes";
import MyCategoryRouter from "./myCategoriesRoutes";

const RootRouter = () => {
	const { getMe } = useUserHook();
	useEffect(() => {
		getMe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const routes = useRoutes([
		{
			path: "/",
			element: <Home />,
		},
		{
			path: "/auth/*",
			element: <AuthRoutes />,
		},
		{
			path: "/flashCards/*",
			element: <FlashcardsRouter />,
		},
		{
			path: "/vocabulary",
			element: <VocabularyHome />,
		},
		{
			path: "/about",
			element: <AboutUs />,
		},
		{
			path: "/events",
			element: <EventsHome />,
		},
		{
			path: "/myCategories/*",
			element: <MyCategoryRouter />,
		},
		{
			path: "/myProfile",
			element: <Profile />,
		},
		{
			path: "/changePassword",
			element: <ChangePassword />,
		},
		{
			path: "/forgotPassword",
			element: <ForgotPassword />,
		},
		{
			path: "/sharedCategory",
			element: <SharedCategories />,
		},
		{
			path: "/privacy-policy",
			element: <PrivacyPolicy />,
		},
	]);
	return routes;
};

export default RootRouter;
