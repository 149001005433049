import { Navigate, Route, Routes } from "react-router-dom";
import AuthenticationPage from "../pages/authentication";
import Login from "../pages/authentication/Login";
import Signup from "../pages/authentication/Signup";
import ResetPassword from "../pages/authentication/ResetPassword";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path='/' element={<AuthenticationPage />} >
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='resetPassword' element={<ResetPassword />} />
        <Route path='' element={<Navigate to='/auth/login' replace />} />
      </Route>
    </Routes>
  )
}