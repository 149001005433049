import { Nav, Stack } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUserContextWrapper } from '../../shared/contexts/UserContextWrapper';

interface Props {
	show: boolean;
	handleClose: () => void;
}

const CustomOffcanvas = styled(Offcanvas)`
	background-color: var(--app-color1);
	.nav-link {
		color: var(--text-white);
		font-size: 1.5vw;
		margin: 8px 0;
	}
	.nav-link:hover {
		font-weight: bold;
	}
	.pointer{
		cursor:pointer;
	}
`

export default function SidebarPanel({ show, handleClose }: Props) {
	const { isLoggedIn } = useUserContextWrapper();
	const navigate = useNavigate();
	const onClick = () => {
		navigate('/')
	}
	return (
		<CustomOffcanvas show={show} onHide={handleClose} placement='end'>
			<Offcanvas.Header closeButton className='text-center '>
				<div className='w-100 pointer'>
					<img width='100' height='100' onClick={onClick} src='/logo.png' alt='App Logo' />
				</div>
			</Offcanvas.Header>
			<Offcanvas.Body className='px-5'>
				<Stack gap={3}>
					<Nav.Link as={Link} to={'/'}>home</Nav.Link>
					<Nav.Link as={Link} to={'/vocabulary'}>vocabulary</Nav.Link>
					<Nav.Link as={Link} to={'/flashcards'}>flashcards</Nav.Link>
					<Nav.Link as={Link} to={'/events'}>events</Nav.Link>
					<Nav.Link as={Link} to={'/about'}>about us</Nav.Link>
					{isLoggedIn && <Nav.Link as={Link} to={'/myCategories'}>my categories</Nav.Link>}
				</Stack>
			</Offcanvas.Body>
		</CustomOffcanvas>
	);
}
