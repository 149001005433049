import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";



type User = {
	userId: string;
	displayName: string;
	email: string;
};

type AllUsersResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: User[];
	};
};


const userAPI = createApi({
	reducerPath: "api/user",
	baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: false,
	tagTypes: ["User"],
	endpoints: (builder) => ({
		allUsers: builder.query<AllUsersResponse,void>({
			query: () => ({
				method: "GET",
				url: `common/allUsers`,
				cacheTime: 10000,
			}),
		}),
	}),
});



export default userAPI;