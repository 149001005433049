import { Container, Row, Col } from "react-bootstrap";
import { Form, FormikProps } from 'formik';
import { Any, IMyCategoryPayload } from "@vokab/shared/src/types";
import { createMyCategorySchema } from "@vokab/shared/src/schemas/common";
import { useAppHook } from "../../../shared/hooks/useAppHook";
import { FormikWithWrapper } from "../../../shared/contexts/FormikWrapper";
import TextInput from "../../../shared/components/form/TextInput";
import { useUserContextWrapper } from "../../../shared/contexts/UserContextWrapper";
import Notify from "../../../shared/components/Notify";
import { instance } from "../../../api";
import React from "react";
import { useNotificationContextWrapper } from "../../../shared/contexts/NotificationContextWrapper";
import api from "../../../store/services/personalCategoryAPI";

interface IProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
	refetch?:()=>void
}

const initialValues = {
  categoryName: ''
};

export default function CreateMyCategoryForm({ setShow, show,refetch }: IProps) {
	const {notifyError,notifySuccess}=useNotificationContextWrapper();
  const { user } = useUserContextWrapper();
	const handleCreateMyCategory = React.useCallback(async (values: IMyCategoryPayload) => {
		try {
			const result = await instance().post('/common/createMyCategory', {
				...values,
				userId: user?.userId || ''
			});

			// await getAllMyCategoriesByUserId({ userId: user?.userId ?? '' });
			notifySuccess(result.data.result.data);
			refetch?.();
		} catch (error) {
			console.error(error);
			notifyError('Error creating category');
		} finally {
			setShow(!show);
		}
	}, [notifyError, notifySuccess, setShow, show, user?.userId]);


  return (
    <>
      <Notify/>
      <FormikWithWrapper
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={createMyCategorySchema as Any}
        onSubmit={handleCreateMyCategory}
      >
        {
          (props: FormikProps<IMyCategoryPayload>) => (
            <Form onSubmit={props.handleSubmit} noValidate style={{
              margin: 10
            }}>
              <Container>
                <Row>
                  <Col className="p-0">
                    <TextInput name="categoryName" className="mb-1" type="text" placeholder="category name" />
                  </Col>
                </Row>
              </Container>
              <div style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'end',
                gap: 5
              }}>
                <button type="submit">Ok</button>
                <button onClick={() => setShow(!show)}>Cancel</button>
              </div>
            </Form>
          )
        }
      </FormikWithWrapper>
    </>
  );
}

