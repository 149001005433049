import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import RootRouter from "./routes/routes";
import { AppContextWrapper } from "./shared/contexts/AppContextWrapper";
import { NotificationContextWrapper } from "./shared/contexts/NotificationContextWrapper";
import { UserContextWrapper } from "./shared/contexts/UserContextWrapper";
import { store } from "./store";

function App() {
	useEffect(() => {
		console.log("App mounted", process.env.REACT_APP_PASSWORD);

		const handleContextMenu = (event: MouseEvent) => {
			if (process.env.NODE_ENV !== "production") return;
			event.preventDefault();
		};

		const handleKeyDown = (event: KeyboardEvent) => {
			if (
				event.ctrlKey &&
				(event.key === "I" ||
					event.key === "i" ||
					event.key === "J" ||
					event.key === "j" ||
					event.key === "U" ||
					event.key === "u" ||
					event.key === "C" ||
					event.key === "c")
			) {
				if (process.env.NODE_ENV !== "production") return;

				event.preventDefault();
			}
		};

		document.addEventListener("contextmenu", handleContextMenu);
		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("contextmenu", handleContextMenu);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);
	return (
		<NotificationContextWrapper>
			<Provider store={store}>
				<AppContextWrapper>
					<UserContextWrapper>
						<BrowserRouter>
							<RootRouter />
						</BrowserRouter>
					</UserContextWrapper>
				</AppContextWrapper>
			</Provider>
		</NotificationContextWrapper>
	);
}

export default App;
