/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useCallback, useEffect,  useRef, useState } from "react";
import { useUserContextWrapper } from "../../../shared/contexts/UserContextWrapper";
import Notify from "../../../shared/components/Notify";
import { Button, ButtonGroup, ListGroup } from "react-bootstrap";
import styled from "styled-components";
import { instance as axios } from "../../../api";
import { useNotificationContextWrapper } from "../../../shared/contexts/NotificationContextWrapper";
import userAPI from "../../../store/services/userAPI";
import personalCategoryApi from "../../../store/services/personalCategoryAPI";
import sharedCategoryAPI from "../../../store/services/sharedCategoryAPI";
const emailsRegex =
	/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},)*([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4})$/;
interface IProps {
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
	categoryName?: any;
}
const Badge = styled.span`
cursor:"pointer",
background-color:"black",
width:"20px",
height:"20px",
color:"white",
border-radius:"50%",
}
`;

const ShareMyCategoryDialog = ({ show, setShow, categoryName }: IProps) => {
	const { notifySuccess, notifyError } = useNotificationContextWrapper();
	const { data } = userAPI.useAllUsersQuery();

	const { user } = useUserContextWrapper();
	const [share, { data: shareResponseData, error: shareError }] =
		personalCategoryApi.useSharePersonnalCategoryMutation();
	useEffect(() => shareError && notifyError(shareError), [shareError]);
	useEffect(
		() => shareResponseData && notifySuccess("category shared successfully"),
		[shareResponseData]
	);

	const [getShared, { data: shared }] =
		sharedCategoryAPI.useGetAllSharedByPersonalCategoryIdMutation();

	const [emails, setEmails] = useState<string>("");

	const fetchShared = useCallback(() => {
		abortControllerRef.current = new AbortController();
		if (!categoryName?.categoryId) return;

		getShared({
			custom_category_id: categoryName?.categoryId,
			from_user_id: user?.userId,
		}).then(() => {});

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort(); // Cancel the request when unmounting
			}
		};
	}, [categoryName?.categoryId, user?.userId]);

	


	const handleDeleteShared = async (id: string) => {
		const params = new URLSearchParams({
			shared_category_id: id,
		});
		try {
			await axios().delete(`/common/categories/shared?${params.toString()}`);
			notifySuccess("category deleted successfully");
			fetchShared();
			return;
		} catch (error: any) {
			notifyError(error.message);
		}
	};

	const abortControllerRef = useRef<AbortController | null>(null);

	useEffect(() => {
		fetchShared();
		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort(); // Cancel the request when unmounting
			}
		};
	}, [fetchShared]);

	const handleShareBtnPress = (
		categoryId: string,
		userId: string,
		emails: string,
		users: {
			displayName: string;
			email: string;
			userId: string;
		}[]
	) => {
		if (!categoryId) return;
		
		share({
			fromUserId: userId,
			toEmails: emails,
			toUserIds: [],
			customCategoryId: categoryName?.categoryId,
		}).then(() => {
			handleResetBtnPress()
			fetchShared();
	
		});
	};
	const handleResetBtnPress = () => setEmails("");

	return (
		<>
			<Notify />
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => setShow(!show)}
				show={show}
			>
				<Modal.Header closeButton>
					<h4>
						<b> share your {categoryName?.categoryName} category </b>
					</h4>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Form.Group className="mb-3">
							<Form.Label>
								enter multiple email addresses (separated by commas)
							</Form.Label>

							<div className="input-group flex-nowrap">
								<input
									value={emails}
									onChange={(e) => setEmails(e.target.value)}
									type="text"
									className="form-control"
									placeholder="emails"
									aria-label="email"
									aria-describedby="addon-wrapping"
								/>
								
								<ButtonGroup className="ms-2" aria-label="Basic example">
									<Button
										variant="primary"
										disabled={!emailsRegex.test(emails)}
										type="submit"
										onClick={() =>
											handleShareBtnPress(
												categoryName?.categoryId,
												user?.userId!,
												emails,
												data?.result?.data!
											)
										}
									>
										share
									</Button>
									<Button
										variant="secondary"
										onClick={handleResetBtnPress}
									>
										clear
									</Button>
								</ButtonGroup>
							</div>
						</Form.Group>
						<Modal.Footer></Modal.Footer>
						<Form.Group className="mb-3">
							<Form.Label>category already shared with users</Form.Label>
							<ListGroup>
								{shared?.result?.data?.result?.map?.((item, index) => (
									<ListGroup.Item className="d-flex justify-content-between">
										{item?.users_shared_category_to_user_idTousers?.email}{" "}
										<span>
											<Button
												onClick={handleDeleteShared.bind(
													null,
													item?.shared_category_id
												)}
												size="sm"
												variant="danger"
											>
												remove
											</Button>
										</span>
									</ListGroup.Item>
								))}
								{shared?.result?.data?.result?.length === 0 && (
									<ListGroup.Item
										disabled
										className="d-flex justify-content-between"
									>
										<span>no users shared with</span>
									</ListGroup.Item>
								)}
							</ListGroup>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ShareMyCategoryDialog;
