import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from 'next-redux-wrapper'
type SignUpArgs = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	confirmPassword: string;
};

type SignUpResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
};
type UserLoginResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
};

type UserLoginPayload = {
	email: string;
	password: string;
};
type ForgetPasswordResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
};

type ResetPasswordArgs = {
	userId: string;
	password: string;
	confirmPassword: string;
	headers?: {
		"auth-token": string;
	};
};

type ResetPasswordResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
};

type UpdateProfileArgs = {
	firstName: string;
	lastName: string;
	userId: string;
	headers?: {
		"auth-token": string;
	};
};

type UpdateProfileResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
};

const authApi = createApi({
	reducerPath: "api/auth",
	baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: false,
	tagTypes: ["Auth"],
	endpoints: (builder) => ({
		userSignup: builder.mutation<SignUpResponse, SignUpArgs>({
			query: (payload) => ({
				method: "POST",
				url: `auth/signup`,
				body: payload,
				cacheTime: 10000,
			}),
			transformErrorResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue.data as Error;
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue as SignUpResponse;
			},
		}),
		userLogin: builder.mutation<UserLoginResponse, UserLoginPayload>({
			query: (payload) => ({
				method: "POST",
				url: `auth/login`,
				body: payload,
				cacheTime: 10000,
				
			}),
			
			transformErrorResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue.data as Error;
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue as UserLoginResponse;
			},
		}),
		userProfileUpdate: builder.mutation<
			UpdateProfileResponse,
			UpdateProfileArgs
		>({
			query: (payload) => ({
				method: "POST",
				url: `auth/updateProfile`,
				body: payload,
				headers: payload.headers,
				cacheTime: 10000,
			}),
			transformErrorResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue.data as Error;
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue as UserLoginResponse;
			},
		}),
		forgetPassword: builder.mutation<ForgetPasswordResponse, { email: string }>(
			{
				query: (payload) => ({
					method: "POST",
					url: `common/forgotPassword`,
					body: payload,
					cacheTime: 10000,
				}),
				transformErrorResponse(baseQueryReturnValue, meta, arg) {
					return baseQueryReturnValue.data as Error;
				},
				transformResponse(baseQueryReturnValue, meta, arg) {
					return baseQueryReturnValue as UserLoginResponse;
				},
			}
		),

		resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordArgs>({
			query: (payload) => ({
				method: "POST",
				url: `auth/resetPassword`,
				body: payload,
				cacheTime: 10000,
			}),
			transformErrorResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue.data as Error;
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue as UserLoginResponse;
			},
		}),

		useSignout: builder.mutation<string, void>({
			queryFn(arg, queryApi, extraOptions, baseQuery) {
				localStorage.removeItem("authToken");
				window.location.href = "/";
				return { data: "You are signed out" };
			},
		}),
	}),
	extractRehydrationInfo(action, { reducerPath, }) {
		if (action.type === "persist/REHYDRATE" && action.payload) {
			return (action.payload as { [key: string]: any })[reducerPath] ?? null;
		}
		return null;
	},
});

export default authApi;
