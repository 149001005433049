import styled, { css } from "styled-components";

export const StyledAbout = styled.div`
  .container-fluid{
    display:flex;
    justify-content:center;
    align-items:center;
    height:calc(100vh - 110px);
  
  }
  .about .profile img {
    width: 12vw;
    height: auto;
  }

  .profile-font {
    font-size: 2vw !important;
    color: #7b7b9b !important;

  }

  .cmn-font {
    font-size: 1.6vw;
    color: #4b4d77;
  }

  .cmn-font-h2 {
    font-size: 3.5vw;
    color: var(--app-color1);
    font-weight: bold;
  }

  /* Media Query */
  @media  (max-width: 549px) {
    .container-fluid{
      text-align:justify;
      height:auto !important;
    }
    .about .profile img {
      width: 35vw !important;
      height: auto;
    }

    .profile-font {
      font-size: 4vw !important;
      color: #7b7b9b;
    }

    .cmn-font {
      font-size: 4vw;
    }

    .cmn-font-h2 {
      font-size: 5vw;
    }
    .mr-auto_m {
      text-align:center

    }
 
    .ms-5{
      margin-left:0 !important;
    }
    .mx-auto_m{
      margin-left:auto !important;
      margin-right:auto !important;
    }
   

  }
  @media (max-width: 767px) and (min-width: 550px) {
    .about .profile img {
      width: 22vw !important;
      height: auto;
    }

    .profile-font {
      font-size: 3vw !important;
      color: #7b7b9b;
    }

    .cmn-font {
      font-size: 3vw;
    }

    .cmn-font-h2 {
      font-size: 4.5vw;
    }
    .mr-auto_m {
      text-align:center

    }
 
    .ms-5{
      margin-left:0 !important;
    }
    .mx-auto_m{
      margin-left:auto !important;
      margin-right:auto !important;
    }
   
  
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    .about .profile img {
      width: 20vw;
      height: auto;
    }

    .profile-font {
      font-size: 3vw;
      color: #7b7b9b;
    }

    .cmn-font {
      font-size: 2vw;
    }

    .cmn-font-h2 {
      font-size: 4vw;
    }

  

    .mr-auto_m {
      text-align:center

    }
    .mx-auto_m{
      margin-left:auto !important;
      margin-right:auto !important;
    }
    .ms-5{
      margin-left:0 !important;
    }
  }

  @media (max-width: 1200px) and (min-width: 992px) {
  
    .about .profile img {
      width: 15vw !important;
      height: auto;
    }

    .profile-font {
      font-size: 2.5vw;
      color: #7b7b9b;
    }

    .cmn-font {
      font-size: 2vw;
    }

    .cmn-font-h2 {
      font-size: 4vw;
    }
  }
`;
