import { Col, Form } from "react-bootstrap";
import { FlashCaredStyle } from "../../pages/flashCards/flashcared";

interface IOptions {
  value: string;
  display: string;
}
interface IProps {
  options: IOptions[];
  onChange: (value: string) => void;
  value: string;
}

export default function SelectDropDown({ options, onChange, value }: IProps) {
  return (
    <FlashCaredStyle>
      <select value={value} onChange={e => onChange(e.target.value)} className="my_categories_font ps-0 " >
        {
          options?.map((v, k) => (
            <option key={k} value={v.value}>{v?.display}</option>
          ))
        }
      </select>
    </FlashCaredStyle>

  );
}