/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import AddMyCategoryDialog from '../../vocabulary/AddMyCategoryDetails';
import { MainCard } from './MainCard';
import { ISelected } from '../../vocabulary/CategoryListTable';
import React from 'react';
import Vokab from '../../../vokab';
import { useSelector } from 'react-redux';
import { store, useAppSelector } from '../../../store';


interface IProps {
  sourceLanguage: string;
  targetLanguage: string;
  sourceLanguageCode: string;
  targetLanguageCode: string;
  selectedValues: ISelected;
	language1VocId:string;
	language2VocId:string;
  index: number;
}

export const CategoryCard = ({ sourceLanguage, targetLanguage,
  sourceLanguageCode, targetLanguageCode, selectedValues,  index,...props }: IProps) => {
		const autoPlay=useAppSelector(state=>state.lessons.autoPlay)
	
  const [isFront, setIsFront] = useState(true);
  const source = sourceLanguage ?? 'No Data';
  const target = targetLanguage ?? 'No Data';
  const [modalShow, setModalShow] = useState(false);
  const word = isFront ? source : target
  const [isSpeaking, ] = useState(false);

	const audioRef = React.useRef<HTMLAudioElement | null>(new Audio());
	const controller=new AbortController();
	async function handleSpeakPress(audioRef:React.MutableRefObject<HTMLAudioElement | null>, sourceLanguageCode:string, sourceLanguage:string, targetLanguageCode:string, isFront:boolean, targetLanguage:string) {
		try {
			await Vokab.playAudio({
				audioRef,
				LanguageCode: isFront ? sourceLanguageCode : targetLanguageCode,
				text: isFront ? sourceLanguage : targetLanguage,
				voc_id: isFront ? props.language1VocId : props.language2VocId
			},controller.signal)
		} catch (error) {
			if (audioRef.current) {
				audioRef.current.pause();
			}
		}
		
	}


	useEffect(()=>{
		if(autoPlay){
			handleSpeakPress(audioRef, sourceLanguageCode, sourceLanguage, targetLanguageCode, isFront, targetLanguage)
		}
		return ()=>{
			if (audioRef.current) {
				audioRef.current.pause();
			}
			controller.abort()
			
		}

	},[autoPlay, controller, handleSpeakPress, isFront, sourceLanguage, sourceLanguageCode, targetLanguage, targetLanguageCode])







  return (
    <div>
      <AddMyCategoryDialog show={modalShow} setShow={setModalShow} state={selectedValues} />
      <MainCard
        speechHandler={()=>handleSpeakPress(audioRef, sourceLanguageCode, sourceLanguage, targetLanguageCode, isFront, targetLanguage)}
        word={word}
				isTamilWord={targetLanguageCode==='ta' && !isFront}
        onShow={() => setModalShow(!modalShow)}
        cardBodyClick={() => setIsFront(!isFront)}
        isSpeaking={isSpeaking}
        
      />
    </div>
  );
};
