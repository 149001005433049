/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col } from "react-bootstrap";
import { LoginButton } from "../../styled-components/common";
import TextInput from "../../shared/components/form/TextInput";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { Form, FormikProps } from "formik";
import { Any, SignupPayload } from "@vokab/shared/src/types";
import { userRegistration } from "@vokab/shared/src/schemas";
import { FormStyle } from "./style";
import Notify from "../../shared/components/Notify";
import authApi from "../../store/services/authAPI";
import { useEffect } from "react";
import { useNotificationContextWrapper } from "../../shared/contexts/NotificationContextWrapper";
import { useNavigate } from "react-router-dom";

const initialValues = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	confirmPassword: "",
};

export default function Signup() {
	const navigate=useNavigate();
	const [signup, { data, error }] = authApi.useUserSignupMutation();
	const { notifyError, notifySuccess } = useNotificationContextWrapper();
	useEffect(() => error && notifyError(error), [error]);
	useEffect(() => {
		if (data) {
			notifySuccess(data.result?.data);
			navigate('/auth/login');
		}
	}, [data, navigate]);
	const register = (values: SignupPayload) => {
		signup(values);
	};

	return (
		<>
			<Notify />
			<FormStyle>
				<h3 className="text-center mt-3 form-title">
					<b>create a new account</b>
				</h3>
				<FormikWithWrapper
					enableReinitialize
					validateOnMount
					initialValues={initialValues}
					validationSchema={userRegistration as Any}
					onSubmit={register}
				>
					{(props: FormikProps<SignupPayload>) => (
						<Form onSubmit={props.handleSubmit} noValidate>
							<Container>
								<Row>
									<Col className="p-0">
										<TextInput
											name="firstName"
											className="mb-1"
											type="text"
											placeholder="first name"
										/>
									</Col>
									<Col className="p-0">
										<TextInput
											name="lastName"
											className="mb-1"
											type="text"
											placeholder="last name"
										/>
									</Col>
								</Row>
							</Container>
							<TextInput
								name="email"
								className="mb-1"
								type="email"
								placeholder="email@address.com"
							/>
							<TextInput
								name="password"
								className="mb-1"
								type="password"
								placeholder="password"
							/>
							<TextInput
								name="confirmPassword"
								className="mb-1"
								type="password"
								placeholder="confirm password"
							/>
							<div className="text-center">
								<LoginButton
									type="submit"
									className="btn btn-primary form-button mx-auto m-3 rounded-5"
								>
									sign up
								</LoginButton>
							</div>
						</Form>
					)}
				</FormikWithWrapper>
			</FormStyle>
		</>
	);
}
