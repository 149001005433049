/* eslint-disable react-hooks/exhaustive-deps */
import { ICategoriesTable } from "@vokab/shared/src/types";
import React, { useEffect, useMemo, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { FiMinus, FiPlus } from "react-icons/fi";
import styled from "styled-components";
import { useAppContextWrapper } from "../../shared/contexts/AppContextWrapper";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import useDebouncedEffect from "../../shared/hooks/debouncedEffectHook";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { useAppDispatch, useAppSelector } from "../../store";
import categoriesAPI from "../../store/services/categoriesAPI";
import { setPreferences } from "../../store/slices/appSlice";
import { Select } from "../../styled-components/common";
import Vokab from "../../vokab";
import { getLanguageNameCode } from "../flashCards/categories";
import AddMyCategoryDialog from "./AddMyCategoryDetails";
import RemoveMyCategoryDialog from "./RemoveMyCategoryDetails";
import { VocabularyTable } from "./vocabularyTable";

const TableScrollContainer = styled.div`
	//  height: calc(100vh - 110px);
	// overflow-y: auto;
`;

export interface ISelected {
	selectedWord: string;
	grammarAssociationId: string;
	categoryId: string;
}

export default function CategoryListTable() {
	const [filterLanguages, setFilterLanguages] = useState({
		"0": "English",
		"1": "Tamil",
		"2": "Telugu",
		"3": "Malayalam",
		"4": "Hindi",
		"5": "Kannada",
	});
	const { search, preferences: filterItems } = useAppSelector(
		(state) => state.appData
	);
	const dispatch = useAppDispatch();
	const { getAllCategoriesGrid } = useAppHook();
	const audioRef = React.useRef<HTMLAudioElement | null>(new Audio());
	const [state, setState] = useState(true);
	const { isLoggedIn, user } = useUserContextWrapper();

	const [modalShow, setModalShow] = useState(false);
	const [removeModelShow, setRemoveModelShow] = useState(false);
	const [selected, setSelected] = useState<ISelected>({
		selectedWord: "",
		grammarAssociationId: "",
		categoryId: "",
	});
	const [newEle, setNewEle] = useState<string[]>([]);
	// const [tableItems, setTableItems] = useState<ICategoriesTableData>({
	// 	totalRecords: 0,
	// 	result: undefined,
	// });

	const { categories, languages, pos, categoriesTableData, myCategories } =
		useAppContextWrapper();
	const { data: competitions } = categoriesAPI.useAllCompetitionsQuery({});

	const isFetching = useAppSelector(
		(state) => state.appData.isGridDataFetching
	);

	const controller = useMemo(() => new AbortController(), []);
	const [isSpeaking, setIsSpeaking] = useState(false);

	const speechHandler = async (
		msg: string,
		langCode: string,
		voc_id: string
	) => {
		setIsSpeaking(true);
		await Vokab.playAudio(
			{
				audioRef,
				LanguageCode: langCode,
				text: msg,
				voc_id: voc_id,
			},
			controller.signal
		).finally(() => setIsSpeaking(false));
	};
	useEffect(() => {
		return () => {
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current = null;
			}
			controller.abort();
		};
	}, [controller]);

	const { sharedCategoriesById } = useAppContextWrapper();

	const languageOptions = useMemo(
		() =>
			[
				{
					languageName: "English",
					languageFamilyId: "",
					languageFamilyName: "default",
					languageId: "",
				},
			].concat(
				languages.map((d) => ({
					languageName: d.languageName,
					languageFamilyId: d.languageFamilyId,
					languageFamilyName:
						d.languageFamilyName.toLocaleLowerCase() === "others"
							? d.languageFamilyName
							: `${d.languageFamilyName} family`,
					languageId: d.languageId,
				}))
			) ?? null,
		[languages]
	);

	const groupingLanguages: { [key: string]: string[] } = languageOptions.reduce(
		(family: any, language) => {
			const key: string = language.languageFamilyName;
			if (!family[key]) {
				family[key] = [];
			}
			family[key].push({
				languageName: language.languageName,
				languageId: language.languageId,
			});
			return family;
		},
		[]
	);
	const groupingCategories: { [key: string]: string[] } = categories.reduce(
		(level: any, categories) => {
			const key: string = categories.profLevel;
			if (!level[key]) {
				level[key] = [];
			}
			level[key].push({
				categoryName: categories.categoryName,
				categoryId: categories.categoryId,
			});
			return level;
		},
		[]
	);

	const addDetailsHandler = (word: string, id: string, categoryId: string) => {
		setSelected((prev: ISelected) => ({
			...prev,
			selectedWord: word,
			grammarAssociationId: id,
			categoryId: categoryId,
		}));
		setModalShow(!modalShow);
	};
	const removeDetailsHandler = (
		word: string,
		id: string,
		categoryId: string
	) => {
		setSelected((prev: ISelected) => ({
			...prev,
			selectedWord: word,
			grammarAssociationId: id,
			categoryId: categoryId,
		}));

		setRemoveModelShow(!removeModelShow);
	};

	// useEffect(() => {
	// 	if (filterItems.start === 0) setTableItems(categoriesTableData);
	// 	else
	// 		setTableItems((prev) => ({
	// 			...prev,
	// 			totalRecords: categoriesTableData?.totalRecords,
	// 			result: [...prev.result!, ...categoriesTableData?.result!],
	// 		}));
	// }, [filterItems, setTableItems, categoriesTableData, categories]);

	const onCategoryChanged = (categoryId: string) => {
		const filter_items = {
			categoryId: categoryId,
			isCompetition: competitions?.result?.data?.result?.find?.(
				(x) => x?.id === categoryId
			)
				? true
				: false,
			isCustomCategory: (myCategories?.result || [])
				.map?.((f) => f?.customCategoryId)
				.concat?.(
					(sharedCategoriesById?.result || []).map?.((c) => c?.customCategoryId)
				)
				.includes(categoryId)
				? true
				: false,
			isSharedCategory: sharedCategoriesById?.result
				?.map((c) => c?.customCategoryId)
				?.includes(categoryId)
				? true
				: false,
			userId: isLoggedIn ? user?.userId : "",
		};
		dispatch(setPreferences(filter_items));
	};

	const onLanguageChanged = (languageIds: string, id: string) => {
		const number = +id?.match(/\d+/)?.[0]! - 1; // Returns "1" or undefined if there is no match
		setFilterLanguages((state) => {
			return {
				...state,
				[number]: languageIds,
			};
		});
	};

	const onGrammarCategoriesChange = (value: string) => {
		// setGrammarCategoryName(value);
		dispatch(
			setPreferences({
				grammarCategoryName: value,
			})
		);
	};

	const onClick = () =>
		setNewEle((e) => (e?.length < 4 ? [...e, `language${e?.length + 3}`] : e));

	const onRemoveHandler = () => setNewEle((e) => e.slice(0, -1));

	const getLanguageName = (
		value: string,
		ct: ICategoriesTable,
		word: string
	) => {
		if (value === "English") return ct.word;
		else return word ?? "No Data";
	};

	const getValue = (value: string, val: ICategoriesTable) => {
		if (value === "language3")
			return getLanguageName(filterLanguages[2], val, val.language2);
		if (value === "language4")
			return getLanguageName(filterLanguages[3], val, val.language3);
		if (value === "language5")
			return getLanguageName(filterLanguages[4], val, val.language4);

		return getLanguageName(filterLanguages[5], val, val.language5);
	};
	const getCode = (value: string, val: ICategoriesTable) => {
		if (value === "language3")
			return getLanguageNameCode(filterLanguages[2], val.language2code);
		if (value === "language4")
			return getLanguageNameCode(filterLanguages[3], val.language3code);
		if (value === "language5")
			return getLanguageNameCode(filterLanguages[4], val.language4code);

		return getLanguageNameCode(filterLanguages[5], val.language5code);
	};
	const getVocId = (value: string, val: ICategoriesTable) => {
		if (value === "language2") {
			return val.language1vocid || val.vocabularyId;
		}
		if (value === "language3") return val.language2vocid || val.vocabularyId;
		if (value === "language4") return val.language3vocid || val.vocabularyId;
		if (value === "language5") return val.language4vocid || val.vocabularyId;

		return val.language5vocid || val.vocabularyId;
	};

	useDebouncedEffect(
		() => {
			const controller = new AbortController();

			if (search!.length > 0) {
				getAllCategoriesGrid({
					...filterItems,
					languageIds: Object.values(filterLanguages),
					searchText: search,
					categoryId: "",
					userId: isLoggedIn ? user?.userId : "",
					signal: controller.signal,
				});

				return;
			}

			if (categories.length) {
				getAllCategoriesGrid({
					...filterItems,
					categoryId: filterItems.categoryId || categories[0]?.categoryId,
					languageIds: Object.values(filterLanguages),
					userId: isLoggedIn ? user?.userId : "",
					signal: controller.signal,
				});
			}
			return () => {
				controller.abort();
			};
		},
		[filterItems, categories, filterLanguages, search, user],
		1000
	);

	return (
		<div>
			<AddMyCategoryDialog
				show={modalShow}
				setShow={setModalShow}
				state={selected}
			/>

			<RemoveMyCategoryDialog
				customCategoryId={filterItems?.categoryId!}
				removeModelShow={removeModelShow}
				setRemoveModelShow={setRemoveModelShow}
				loadGridData={() => getAllCategoriesGrid(filterItems)}
				state={selected}
			/>

			<VocabularyTable>
				<Row className="col-11 mx-auto">
					<TableScrollContainer
						id="scrollableDiv "
						style={{
							height: "calc(100vh - 140px)",
						}}
						className="p-0"
					>
						<Table
							onCopy={(e) => {
								e.preventDefault();
								// alert("Copying is disabled");
							}}
							responsive
						>
							<thead className="d-flex  align-items-center flex-wrap col-12">
								<div className="d-flex col-12 flex-wrap   align-items-center">
									<tr className=" col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-11 d-flex justify-content-between align-items-center p-0">
										{state && (
											<th className="ps-0">
												<div className="my_vokab_table flex-wrap my_vokab_table_font d-flex align-items-center justify-content-center">
													<div className="tr-1 text-left">
														<AiFillCaretLeft onClick={() => setState(!state)} />
													</div>
													<div className="tr-11">
														<Select
															name="category"
															id="category"
															onChange={(e) =>
																onCategoryChanged(e.target.value)
															}
															value={
																filterItems.categoryId ||
																categories[0]?.categoryId
															}
															className="d-flex justify-content-center align-items-center"
														>
															<option value="" disabled className="option">
																vokab bowl competitions
															</option>
															{competitions?.result?.data?.result?.map?.(
																(x: any, y: number) => (
																	<option key={y} value={x?.id}>
																		{x?.title?.toLowerCase()}
																	</option>
																)
															)}
															{isLoggedIn ? (
																<>
																	<option value="" disabled className="option">
																		my categories
																	</option>

																	{myCategories?.result?.map((mC, i) => {
																		return (
																			<option
																				key={i}
																				value={mC.customCategoryId}
																			>
																				{mC?.categoryName.toLowerCase()}
																			</option>
																		);
																	})}
																	{sharedCategoriesById?.result?.length >= 1 ? (
																		<>
																			<option
																				value=""
																				disabled
																				className="option"
																			>
																				shared categories
																			</option>

																			{sharedCategoriesById?.result?.map(
																				(x: any, y: number) => (
																					<option
																						key={y}
																						value={x.customCategoryId}
																					>
																						{x.categoryName.toLowerCase()}
																					</option>
																				)
																			)}
																		</>
																	) : (
																		""
																	)}
																	{Object.entries(groupingCategories).map(
																		(value: any, key) => (
																			<>
																				<option
																					key={key}
																					value={value[0]}
																					className="scrollbar option"
																					disabled
																				>
																					public categories -{" "}
																					{value[0].toLowerCase()}
																				</option>
																				{value[1].map((x: any, y: string) => (
																					<option key={y} value={x.categoryId}>
																						{x.categoryName.toLowerCase()}
																					</option>
																				))}
																			</>
																		)
																	)}
																</>
															) : (
																<>
																	{Object.entries(groupingCategories).map(
																		(value: any, key) => (
																			<>
																				<option
																					key={key}
																					value={value[0]}
																					className="scrollbar option"
																					disabled
																				>
																					public categories -{" "}
																					{value[0].toLowerCase()}
																				</option>

																				{value[1].map((x: any, y: string) => (
																					<option key={y} value={x.categoryId}>
																						{x.categoryName.toLowerCase()}
																					</option>
																				))}
																			</>
																		)
																	)}
																</>
															)}
														</Select>
													</div>
												</div>
											</th>
										)}
										<th>
											<div className="d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font">
												{!state && (
													<div className="">
														<AiFillCaretRight
															onClick={() => setState(!state)}
														/>{" "}
													</div>
												)}

												<div className="d-flex flex-wrap d-flex flex-wrap  flex-grow-1">
													<div className="col-12 ">
														<Select
															name="profLevel"
															id="profLevel"
															className="d-flex justify-content-center align-items-center p-0 text-truncate"
															value={filterItems.grammarCategoryName}
															onChange={(e) =>
																onGrammarCategoriesChange(e.target.value)
															}
															title="parts of speech(POS)"
														>
															<option value="" selected>
																part of speech(POS)
															</option>
															{pos.map((p, i) => (
																<option key={i} value={p.grammarCategoryName}>
																	{p.grammarCategoryName.toLowerCase()}
																</option>
															))}
														</Select>
													</div>
												</div>
											</div>
										</th>
										<th>
											<div className="d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font">
												<div className="col-12 p-0">
													<Select
														id={"language1"}
														onChange={(e) =>
															onLanguageChanged(e.target.value, "language1")
														}
														value={filterLanguages[0]}
														className="p-0"
													>
														{Object.entries(groupingLanguages).map((l, i) => (
															<>
																<option
																	key={i}
																	value={l[0]}
																	disabled
																	className="option"
																>
																	<b>{l[0].toLowerCase()} </b>
																</option>
																{l[1].map((x: any, y) => (
																	<option key={y} value={x.languageName}>
																		{x.languageName.toLowerCase()}
																	</option>
																))}
															</>
														))}
													</Select>
												</div>
											</div>
										</th>
										<th className="pe-0">
											<div className="d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font">
												{/* <div className="tr-1 text-left">
													<FaSearch />
												</div> */}
												<div className="col-12 p-0">
													<Select
														id={"language2"}
														onChange={(e) =>
															onLanguageChanged(e.target.value, "language2")
														}
														value={filterLanguages[1]}
														className="d-flex justify-content-center align-items-center p-0"
													>
														{Object.entries(groupingLanguages).map((l, i) => (
															<>
																<option
																	key={i}
																	value={l[0]}
																	disabled
																	className="option"
																>
																	<b>{l[0].toLowerCase()}</b>
																</option>
																{l[1].map((x: any, y) => (
																	<option key={y} value={x.languageName}>
																		{x.languageName.toLowerCase()}
																	</option>
																))}
															</>
														))}
													</Select>
												</div>
											</div>
										</th>
										{newEle.map((v, key) => (
											<th key={key} className="pe-0">
												<div className="d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font">
													{/* <div className="tr-1 text-left">
														<FaSearch />
													</div> */}
													<div className="col-12 p-0">
														<Select
															id={key.toString()}
															onChange={(e) =>
																onLanguageChanged(e.target.value, v)
															}
															value={
																filterLanguages[
																	(key +
																		2) as unknown as keyof typeof filterLanguages
																]
															}
															className="d-flex justify-content-center align-items-center p-0"
														>
															{Object.entries(groupingLanguages).map((l, i) => (
																<>
																	<option
																		key={i}
																		value={l[1]}
																		disabled
																		className="option"
																	>
																		<b>{l[0].toLowerCase()}</b>
																	</option>
																	{l[1].map((x: any, y) => (
																		<option key={y} value={x.languageName}>
																			{x.languageName.toLowerCase()}
																		</option>
																	))}
																</>
															))}
														</Select>
													</div>
												</div>
											</th>
										))}
									</tr>
									<tr className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-1 d-flex justify-content-between align-items-center text-center pointer  d-none d-lg-block">
										<th className="p-0 ">
											<div
												className="sound col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"
												style={{
													display: "flex",
													gap: 2,
													alignItems: "center",
												}}
											>
												<FiPlus
													onClick={onClick}
													size={35}
													className="plus-border plus-table-border vokab-table-font p-0"
													style={{ background: "#D3D2D8" }}
												/>
												<FiMinus
													onClick={onRemoveHandler}
													size={35}
													className="plus-border plus-table-border vokab-table-font p-0"
													style={{ background: "#D3D2D8" }}
												/>
											</div>
										</th>
									</tr>
								</div>
							</thead>
							<tbody className="d-flex justify-content-center align-items-baseline flex-wrap scrollbar scrollbar-primary ">
								{isFetching && !isSpeaking ? (
									<tr className="d-flex justify-content-center align-items-center col-12">
										<Spinner animation="border" role="status">
											<span className="visually-hidden">Loading...</span>
										</Spinner>
									</tr>
								) : null}
								{categoriesTableData?.result?.length === 0 ? (
									<tr className="d-flex justify-content-center align-items-center col-12">
										<div className="d-flex justify-content-center align-items-center col-12">
											No Data Found
										</div>
									</tr>
								) : null}

								{categoriesTableData?.result?.map((ct, i) => (
									<React.Fragment key={i}>
										<div className="d-flex flex-wrap  justify-content-center align-items-center col-12">
											<tr className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-11 d-flex justify-content-between align-items-center my_vokab_table my_vokab_table_font  mt-2 py-1 px-0">
												{state && (
													<td className="d-flex justify-content-between px-0">
														<div className="mx-auto">
															{ct?.categoryName.toLowerCase() ?? ""}
														</div>
													</td>
												)}
												<td className="d-flex justify-content-between px-0 border-left">
													<div className="mx-auto">
														{filterItems.grammarCategoryName
															? filterItems.grammarCategoryName
															: ct.grammarCategoryName}
													</div>
												</td>

												<td
													className="d-flex justify-content-between px-0 border-left"
													onClick={() =>
														speechHandler(
															ct.language0 || ct.word,
															ct.language0code || "en",
															ct.language0vocid || ct.vocabularyId
														)
													}
												>
													<div
														className={"mx-auto ".concat(
															ct.language0code === "ta" ? "ta-text fs-6" : ""
														)}
													>
														{getLanguageName(
															filterLanguages[0],
															ct,
															ct.language0
														)}
													</div>
												</td>

												<td
													className="text-center px-0 border-left"
													onClick={() =>
														speechHandler(
															getLanguageName(
																filterLanguages[1],
																ct,
																ct.language1
															),
															getLanguageNameCode(
																filterLanguages[1],
																ct.language1code
															),
															ct.language1vocid || ct.vocabularyId
														)
													}
												>
													<div
														className={
															ct.language1code === "ta"
																? "mx-auto ta-text fs-6"
																: "mx-auto"
														}
													>
														{getLanguageName(
															filterLanguages[1],
															ct,
															ct.language1
														)}
													</div>
												</td>

												{newEle.map((f, i) => (
													<td
														key={f}
														className="d-flex justify-content-between px-0 text-wrap border-left"
														onClick={() =>
															speechHandler(
																getValue(f, ct),
																getCode(f, ct),
																getVocId(f, ct)
															)
														}
													>
														<div
															className={
																ct[
																	`language${
																		i + 2
																	}code` as keyof ICategoriesTable
																] === "ta"
																	? "mx-auto ta-text fs-6"
																	: "mx-auto"
															}
														>
															{getValue(f, ct)}
														</div>
													</td>
												))}
											</tr>

											<tr className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-1 d-flex justify-content-between align-items-center  pointer  py-1  mt-2 mx-0 ms-">
												<td className="p-0">
													<div
														className="sound col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex align-items-center p-0"
														style={{
															gap: 2,
														}}
													>
														<FiPlus
															size={35}
															className="plus-border plus-table-border vokab-table-font "
															onClick={() =>
																addDetailsHandler(
																	ct.word,
																	ct.vocabularyGrammarAssociationId,
																	ct.categoryId
																)
															}
														/>
														{isLoggedIn &&
															myCategories.result
																.map((x) => x.categoryName)
																.includes(filterItems?.categoryId!) && (
																<>
																	<FiMinus
																		id="removeCategories"
																		size={35}
																		className="plus-border plus-table-border vokab-table-font p-0"
																		onClick={() =>
																			removeDetailsHandler(
																				ct.word,
																				ct.vocabularyGrammarAssociationId,
																				ct.categoryId
																			)
																		}
																	/>
																</>
															)}
													</div>
												</td>
											</tr>
										</div>
									</React.Fragment>
								))}
							</tbody>
						</Table>
					</TableScrollContainer>
				</Row>
			</VocabularyTable>
		</div>
	);
}
