import { Any, CommonSuccessAPIResponse } from "@vokab/shared/src/types";
import axios, { AxiosResponse, CancelToken, Method } from "axios";
import { store } from "../store";
import { setFetching } from "../store/slices/appSlice";



export const instance = () =>
	axios.create({
		baseURL: "/api/",
	});

instance().interceptors.request.use(
	async (config) => {

		// Introduce a delay of 2000 milliseconds (2 seconds) before making the request
		await new Promise((resolve) => setTimeout(resolve, 5000));

		return config
	},
	(error) => {
		return Promise.reject(error);
	}
);

const setAuthToken = () => {
	const token = localStorage.getItem("authToken");
	if (!token) return {};
	return {
		headers: {
			"auth-token": token ? `Bearer ${token}` : "",
		},
	};
};

const request = (
	method: Method,
	url: string,
	data: Any,
	params?: Any,
	signal?: AbortSignal,
) => {
	let response = null;

	switch (method) {
		case "PUT":
			store.dispatch(setFetching(true))	;
			response = instance()
				.put(url, { ...data, ...setAuthToken(),signal })
				.finally(() => {
					store.dispatch(setFetching(false));
				});

			return response;

		case "POST":
			store.dispatch(setFetching(true))	;
			response = instance()
				.post(url, { ...data, ...setAuthToken(),signal })
				.finally(() => {
					store.dispatch(setFetching(false));				});

			return response;

		case "GET":
			store.dispatch(setFetching(true))	;

			response = instance()
				.get(url, { ...data, ...setAuthToken(), params,signal})
				.finally(() => {
					store.dispatch(setFetching(false));				});

			return response;

		case "DELETE":
			store.dispatch(setFetching(true))	;
			response = instance()
				.delete(url, { data, ...setAuthToken()})
				.finally(() => {
					store.dispatch(setFetching(false));				});

			return response;

		default:
			return response;
	}
};

export type ApiCallProps = {
	method: Method;
	url: string;
	data?: Any;
	params?: Any;
	/* By default, show Loading Spinner.  Use isNotWithLoading to call without loading spinner */
	isNotWithLoading?: boolean
};

export type CustomResponse<T> = [CommonSuccessAPIResponse<T>, Any];

const asCustomResponse = <T>(promise: Any): CustomResponse<T> =>
	promise
		.then((res: AxiosResponse<CommonSuccessAPIResponse<T>>) => [res.data, null])
		.catch((e: Any) => [null, e]);

export const apiCall = async <T>(
	options: ApiCallProps,
	signal?: AbortSignal
) => {
	const { method, url, data, params} = options;
	return asCustomResponse<T>(
		request(method, url, data, params,signal)
	);
};

export const apiCallWithLoading = async <T>(
	options: ApiCallProps,
	signal?: AbortSignal
) => {
	const { method, url, data} = options;
	return asCustomResponse<T>(
		request(method, url, data, undefined)
	);
};

export const getApiErrorDataMessage = (error: Any): string => {
	if (typeof error === "string") return error;
	if (axios.isAxiosError(error)) return error.response?.data.message;
	if (
		typeof error === "object" &&
		error &&
		Object.prototype.hasOwnProperty.call(error, "message")
	)
		return error.message;
	return "unknown error";
};
