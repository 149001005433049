
import { Layout } from "./Layout";
import VokabSection from "./VokabDefinitionSection";

export default function Home() {
	return (
		<Layout>
			<div className="mt-4 container-fluid col-12 d-flex justify-content-center align-items-center" id="vocabulary">
				<VokabSection />
			</div>
		</Layout>
	)
}