import { Container, Row, Col } from "react-bootstrap";
import { Field, Form, FormikProps } from 'formik';
import { Any, IMyCategoryDetailsPayload, IMyCategoryPayload } from "@vokab/shared/src/types";
import { addMyCategoryDetailsSchema } from "@vokab/shared/src/schemas/common";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { useMemo, useEffect } from "react";
import { useAppContextWrapper } from "../../shared/contexts/AppContextWrapper";
import { ISelected } from "./CategoryListTable";
import TextInput from "../../shared/components/form/TextInput";

interface IProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selected: ISelected;
}

export default function AddMyCategoryDetailsForm({ setShow, show, selected }: IProps) {
  const { addCategoryDetails, getAllMyCategoriesByUserId } = useAppHook();
  const { user } = useUserContextWrapper();
  const mapPayload = (values: IMyCategoryDetailsPayload) => ({
    customCategoryId: values.customCategoryId ?? '',
    userId: user?.userId ?? '',
    word: selected.selectedWord ?? '',
    vocabularyGrammarAssociationId: selected.grammarAssociationId ?? '',
    categoryId: selected.categoryId ?? ''
  })
  const { myCategories } = useAppContextWrapper();
  const userId = useMemo(() => user?.userId ?? '', [user])
  const options = useMemo(() => myCategories?.result?.map(k => ({
    value: k.customCategoryId,
    display: k.categoryName
  })), [myCategories])
  useEffect(() => {
    if (userId)
      Promise.all([
        getAllMyCategoriesByUserId({ userId: userId })
      ]).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  const register = async (values: IMyCategoryDetailsPayload) => {
    await addCategoryDetails(mapPayload(values));
    // await getAllMyCategoriesByUserId({ userId: user?.userId ?? '' })
    setShow(!show)
  };
  const initialValues = {
    customCategoryId: ''
  };

  return (
    <>
      <FormikWithWrapper
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={addMyCategoryDetailsSchema as Any}
        onSubmit={register}
      >
        {
          (props: FormikProps<IMyCategoryPayload>) => (
            <Form onSubmit={props.handleSubmit} noValidate style={{
              margin: 10
            }}>
              <Container>
                <Row>
                  <Col className="p-0">
                    <TextInput name="customCategoryId" as={'select'} className="mb-1" type="text" placeholder="customCategoryId" >
                      <option disabled value="">my category</option>
                      {options.map((c, i) => (
                        <option key={i} value={c.value}>
                          {c?.display}
                        </option>
                      ))}
                    </TextInput>
                  </Col>
                </Row>
              </Container>
              <div style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'end',
                gap: 5
              }}>
                <button type="submit">Ok</button>
                <button onClick={() => setShow(!show)}>Cancel</button>
              </div>
            </Form>
          )
        }
      </FormikWithWrapper>
    </>
  );
}