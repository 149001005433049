import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory, IProfLevel } from "@vokab/shared/src/types";

export interface IVocabularyLanguage {
	id: string;
	vocabulary_id: string;
	languageId: string;
	word: string;
	tts_text: null;
	language: {
		languageId: string;
		languageFamilyId: string;
		languageCode: string;
		languageName: string;
		displayFlag: boolean;
		displayOrder: null;
	};
}
const proLevelSlice = createSlice({
	name: "profLevels",
	initialState: {
		data: [] as IProfLevel[],
		selectedProfLevel: {} as IProfLevel,
		selectedCategory: {} as ICategory,
		selectedTargetVocabulary: {} as IVocabularyLanguage,
	},
	reducers: {
		// setProfLevels(state, action: PayloadAction<IProfLevel[]>) {
		// 	state.data = action.payload;

		// 	state.selectedProfLevel = state.selectedProfLevel?.profLevelId
		// 		? state.selectedProfLevel
		// 		: action.payload[0];
		// 	state.selectedCategory = state.selectedCategory?.categoryId
		// 		? state.selectedCategory
		// 		: action.payload[0].categories[0];
		// },
		// setSelectedProfLevel(state, action: PayloadAction<IProfLevel>) {
		// 	state.selectedProfLevel = action.payload;
		// 	state.selectedCategory = action.payload.categories[0];
		// },
		// setSelectedCategory(state, action: PayloadAction<ICategory>) {
		// 	state.selectedCategory = action.payload;
		// },
		// setTargetVocabulary(state, action: PayloadAction<IVocabularyLanguage>) {

		// 	state.selectedTargetVocabulary = action.payload;

		// },
	},
});

export const { actions: proLevelActions, reducer: proLevelReducer } =
	proLevelSlice;
