import React, { useContext, createContext, useState } from 'react';

export interface IFlashCardsContextState {
  categories: string;
  sourceLanguage: string;
  targetLanguage: string;
  categoryName: string;
  customCategoryId: string;
}

export interface FlashCardsContextProps {
  children: React.ReactNode;
}

const initialValues = {
  categories: '',
  sourceLanguage: '',
  targetLanguage: '',
  categoryName: '',
  customCategoryId: ''
};

export interface IFlashCardsContext extends IFlashCardsContextState {
  FlashCardsStateSetter: React.Dispatch<React.SetStateAction<IFlashCardsContextState>>;
}

const FlashCardsContext = createContext<IFlashCardsContext | null>(null);

export const useFlashCardsContextWrapper = () => useContext(FlashCardsContext) as IFlashCardsContext;

export const FlashCardsContextWrapper = ({ children }: FlashCardsContextProps) => {
  const [flashCardsState, FlashCardsStateSetter] = useState<IFlashCardsContextState>(initialValues);

  return (
    <FlashCardsContext.Provider value={{
      ...flashCardsState,
      FlashCardsStateSetter
    }}>
      {children}
    </FlashCardsContext.Provider>
  );
}