import { Any } from '@vokab/shared/src/types';
import { getIn } from 'formik';
import * as Yup from 'yup';

export const isRequiredField = (validationSchema: Yup.ObjectSchema<Any>, fieldName: string) => {
	const schema = fieldName.split('.').reduce((acc, field) => {
		const tests = getIn(acc.fields, field);
		if (tests)
			acc = tests;
		return acc;
	}, validationSchema.describe());

	if (schema && schema.tests)
		return !!schema.tests.find(({ name }) => name === 'required');

	return false;
};