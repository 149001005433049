import * as Yup from 'yup';
import {
	checkStringRequiredLength,
} from '../validation/validators';

export const createMyCategorySchema = Yup.object({
	categoryName: checkStringRequiredLength()
});

export const addMyCategoryDetailsSchema = Yup.object({
	customCategoryId: checkStringRequiredLength()
});

export const shareCategorySchema = Yup.object({
	toUserId: Yup
			.array().of(Yup.string())
			.min(1, 'Please select at least one user')
});